import {
  type Observable,
  BehaviorSubject,
  filter,
  switchMap,
  Subject,
  Subscription,
  shareReplay,
  tap,
  timer,
} from "rxjs";

export interface Store<T> {
  get: () => T;
  set: (value: T) => void;
  value$: Observable<T>;
}

export interface OptionalStore<T> {
  get: () => T | undefined;
  set: (value: T | undefined) => void;
  value$: Observable<T | undefined>;
}

export interface PersistentStore<T> extends Store<T> {
  start: () => Subscription;
  stop: () => void;
  set: (value: T, persist?: boolean) => void;
  loading$: Observable<boolean>;
}

export const createStore = <T>(initialValue: T): Store<T> => {
  const value$$ = new BehaviorSubject<T>(initialValue);
  const get = () => value$$.getValue();
  const set = (newValue: T) => {
    value$$.next(newValue);
  };
  return {
    get,
    set,
    value$: value$$.pipe(shareReplay(1)),
  };
};

export interface AsyncStore<T> {
  get: () => T | undefined;
  set: (value: T) => void;
  fetchData: (...args: any[]) => Promise<T>;
  loading$: Observable<boolean>;
  error$: Observable<boolean>;
  value$: Observable<T | undefined>;
}

export const createAsyncStore = <T>(fetch: (...args: any[]) => Promise<T>, initialValue?: T): AsyncStore<T> => {
  const store = createOptionalStore<T>(initialValue);
  const loading$$ = new BehaviorSubject<boolean>(false);
  const error$$ = new BehaviorSubject<boolean>(false);
  return {
    get: store.get,
    set: store.set,
    fetchData: async (...args: any[]) => {
      error$$.next(false);
      loading$$.next(true);
      try {
        const value = await fetch(...args);
        store.set(value);
        loading$$.next(false);
        return value;
      } catch (e) {
        loading$$.next(false);
        error$$.next(true);
        throw e;
      }
    },
    loading$: loading$$.asObservable(),
    error$: error$$.asObservable(),
    value$: store.value$,
  };
};

export const createOptionalStore = <T>(initialValue?: T): OptionalStore<T> => {
  const value$$ = new BehaviorSubject<T | undefined>(initialValue);
  const get = () => value$$.getValue();
  const set = (newValue: T | undefined) => {
    value$$.next(newValue);
  };
  return {
    get,
    set,
    value$: value$$.asObservable(),
  };
};

export const createPersistentStore = <T, U = T>(
  initialValue: T,
  onUpdate: (v: U) => Promise<T>,
  time = 200,
): PersistentStore<T> => {
  let subscription: Subscription | undefined;
  const value$$ = new BehaviorSubject<T>(initialValue);
  const loading$$ = new BehaviorSubject<boolean>(false);
  const persist$$ = new Subject<T>();
  const get = () => value$$.getValue();
  const set = (newValue: T, persist = true) => {
    value$$.next(newValue);
    if (persist) {
      persist$$.next(newValue);
    }
  };

  const persist$ = persist$$.pipe(
    switchMap((v) => {
      return timer(time).pipe(
        switchMap(async () => {
          loading$$.next(true);
          try {
            const value = await onUpdate(v as unknown as U);
            loading$$.next(false);
            return value;
          } catch (e) {
            console.error(e);
            loading$$.next(false);
          }
        }),
        filter(Boolean),
      );
    }),
    tap((v) => set(v, false)),
  );

  const value$ = value$$.pipe(shareReplay(1));

  return {
    get,
    set,
    value$,
    loading$: loading$$.asObservable(),
    start: () => {
      subscription = persist$.subscribe();
      return subscription;
    },
    stop: () => {
      subscription?.unsubscribe();
    },
  };
};
