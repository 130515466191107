import { pluckFirst, useObservable, useObservableState } from "observable-hooks";
import { Store } from "../core";
import isEqual from "fast-deep-equal";
import { Observable, distinctUntilChanged, filter, map, switchMap, tap } from "rxjs";

type StoreValue<T extends Store<any>> = ReturnType<T["get"]>;

export const useStore = <T extends Store<any>, S = StoreValue<T>>(
  store: T,
  selector?: (v: StoreValue<T>) => S,
  debug = false,
) => {
  const store$ = useObservable(pluckFirst, [store]);
  const value$ = useObservable(() =>
    store$.pipe(
      switchMap((s) => s.value$),
      map((v) => (selector ? selector(v) : v)),
      tap((v) => debug && console.log("useStore", v)),
      distinctUntilChanged(isEqual),
      // share(),
    ),
  );
  const value = useObservableState(value$, selector && store?.get() ? selector(store?.get()) : store?.get());
  return value as S;
};

export const useOptionalStore = <T extends Store<any>, S = StoreValue<T>>(
  store?: T,
  selector?: (v: StoreValue<T>) => S,
  debug = false,
) => {
  const store$ = useObservable(pluckFirst, [store]);
  const value$ = useObservable(() =>
    store$.pipe(
      filter((s): s is T => s !== undefined),
      switchMap((s) => s.value$),
      map((v) => (selector ? selector(v) : v)),
      tap((v) => debug && console.log("useStore", v)),
      distinctUntilChanged(isEqual),
      // share(),
    ),
  );
  const value = useObservableState(value$, selector && store?.get() ? selector(store?.get()) : store?.get());
  return value as S | undefined;
};

export const useObservableValue = <T, S = T>(o$: Observable<T>, selector?: (v: T) => S) => {
  const obs$ = useObservable(pluckFirst, [o$]);
  const value$ = useObservable(() =>
    obs$.pipe(
      filter((v): v is Observable<T> => v !== undefined),
      switchMap((v) => v),
      filter((s): s is T => s !== undefined),
      map((v) => (selector ? selector(v) : v)),
      distinctUntilChanged(isEqual),
    ),
  );
  const value = useObservableState(value$, undefined);
  return value as S;
};

export const useOptionalObservableValue = <T, S = T>(o$?: Observable<T>, selector?: (v: T) => S) => {
  const obs$ = useObservable(pluckFirst, [o$]);
  const value$ = useObservable(() =>
    obs$.pipe(
      filter((v): v is Observable<T> => v !== undefined),
      switchMap((v) => v),
      filter((s): s is T => s !== undefined),
      map((v) => (selector ? selector(v) : v)),
      distinctUntilChanged(isEqual),
    ),
  );
  const value = useObservableState(value$, undefined);
  return value as S | undefined;
};
